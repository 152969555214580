"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarSize = exports.AvatarType = void 0;
var AvatarType;
(function (AvatarType) {
    AvatarType["IMG"] = "img";
    AvatarType["ICON"] = "icon";
    AvatarType["TEXT"] = "text";
})(AvatarType = exports.AvatarType || (exports.AvatarType = {}));
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["SMALL"] = "small";
    AvatarSize["MEDIUM"] = "medium";
    AvatarSize["LARGE"] = "large";
})(AvatarSize = exports.AvatarSize || (exports.AvatarSize = {}));

export const MULTI_STEP_TYPES = {
  PRIMARY: "primary",
  SUCCESS: "success",
  DANGER: "danger",
  DARK: "dark"
};

export const STEP_STATUSES = {
  PENDING: "pending",
  ACTIVE: "active",
  FULFILLED: "fulfilled"
};

export const TEXT_PLACEMENTS = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical"
};

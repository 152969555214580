"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSliderActions = exports.useSliderSelection = exports.useSliderInfix = exports.useSliderUi = exports.SliderProvider = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const testid_helper_1 = require("../../helpers/testid-helper");
const SliderHooks_1 = require("./SliderHooks");
const UiContext = (0, react_1.createContext)({});
const SelectionContext = (0, react_1.createContext)({});
const ActionsContext = (0, react_1.createContext)({});
const InfixContext = (0, react_1.createContext)({});
function SliderProvider({ children, ariaLabel, ariaLabelledby, color, "data-testid": dataTestId, defaultValue, disabled, max, min, onChange, ranged, showValue, size, step, value, valueFormatter, valueText, infixOptions }) {
    const shapeTestId = (0, testid_helper_1.createTestIdHelper)(dataTestId);
    const { actualValue, actualValueText, getSelectedValue, setSelectedValue } = (0, SliderHooks_1.useSliderValues)(defaultValue, value, valueFormatter, valueText);
    const [active, setActive] = (0, react_1.useState)(null);
    const [focused, setFocused] = (0, react_1.useState)(null);
    const [dragging, setDragging, getDragging] = (0, SliderHooks_1.useDragging)();
    const uiContextValue = (0, react_1.useMemo)(() => ({ active, ariaLabel, ariaLabelledby, color, disabled, dragging, focused, size, shapeTestId, showValue }), [active, ariaLabel, ariaLabelledby, color, disabled, dragging, focused, size, shapeTestId, showValue]);
    const selectionContextValue = (0, react_1.useMemo)(() => ({
        max,
        min,
        ranged,
        step,
        value: actualValue,
        valueText: actualValueText
    }), [max, min, ranged, step, actualValue, actualValueText]);
    const actionsContextValue = (0, SliderHooks_1.useSliderActionsContextValue)(actualValue, focused, getDragging, getSelectedValue, max, min, onChange, ranged, setActive, setFocused, setDragging, setSelectedValue, step);
    return ((0, jsx_runtime_1.jsx)(UiContext.Provider, Object.assign({ value: uiContextValue }, { children: (0, jsx_runtime_1.jsx)(SelectionContext.Provider, Object.assign({ value: selectionContextValue }, { children: (0, jsx_runtime_1.jsx)(ActionsContext.Provider, Object.assign({ value: actionsContextValue }, { children: (0, jsx_runtime_1.jsx)(InfixContext.Provider, Object.assign({ value: infixOptions }, { children: children })) })) })) })));
}
exports.SliderProvider = SliderProvider;
function useSliderUi() {
    return (0, react_1.useContext)(UiContext);
}
exports.useSliderUi = useSliderUi;
function useSliderInfix() {
    return (0, react_1.useContext)(InfixContext);
}
exports.useSliderInfix = useSliderInfix;
function useSliderSelection() {
    return (0, react_1.useContext)(SelectionContext);
}
exports.useSliderSelection = useSliderSelection;
function useSliderActions() {
    return (0, react_1.useContext)(ActionsContext);
}
exports.useSliderActions = useSliderActions;

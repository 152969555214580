"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useAfterFirstRender() {
    const isAfterFirstRender = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(() => {
        window.requestAnimationFrame(() => {
            isAfterFirstRender.current = false;
        });
    }, []);
    return isAfterFirstRender;
}
exports.default = useAfterFirstRender;

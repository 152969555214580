"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Placement = void 0;
var Placement;
(function (Placement) {
    Placement["RIGHT"] = "right";
    Placement["RIGHT_START"] = "right-start";
    Placement["RIGHT_END"] = "right-end";
    Placement["LEFT"] = "left";
    Placement["LEFT_START"] = "left-start";
    Placement["LEFT_END"] = "left-end";
})(Placement = exports.Placement || (exports.Placement = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const SliderHelpers_1 = require("../SliderHelpers");
function defineFilledTrackProps(dimension, offset, reverse) {
    if (reverse) {
        return {
            right: `${offset}%`,
            width: `${dimension - offset}%`
        };
    }
    return {
        left: `${offset}%`,
        width: `${dimension - offset}%`
    };
}
const SliderFilledTrack = ({ className, dimension = 0, offset = 0, reverse = false }) => {
    const filledTrackStyle = defineFilledTrackProps(dimension, offset, reverse);
    return (0, jsx_runtime_1.jsx)("div", { className: (0, SliderHelpers_1.bem)("filled-track", "", className), style: filledTrackStyle });
};
exports.default = SliderFilledTrack;

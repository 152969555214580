"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogSize = exports.DialogType = void 0;
var DialogType;
(function (DialogType) {
    DialogType["MODAL"] = "modal";
    DialogType["POPOVER"] = "popover";
})(DialogType = exports.DialogType || (exports.DialogType = {}));
var DialogSize;
(function (DialogSize) {
    DialogSize["NONE"] = "none";
    DialogSize["SMALL"] = "small";
    DialogSize["MEDIUM"] = "medium";
    DialogSize["LARGE"] = "large";
})(DialogSize = exports.DialogSize || (exports.DialogSize = {}));

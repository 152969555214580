"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customPropTypes = exports.validateValue = void 0;
const validateValue = (value) => {
    const isNullOrUndefined = value === null || value === undefined;
    const isEmptyStringWithSpaces = typeof value === "string" && !value.replace(" ", "").length;
    return isNullOrUndefined || isNaN(Number(value)) || isEmptyStringWithSpaces;
};
exports.validateValue = validateValue;
const range = (min, max) => (props, propName, componentName) => {
    if (props[propName] < min || props[propName] > max) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}. ${propName} should be between ${min} to ${max}.`);
    }
};
exports.customPropTypes = Object.freeze({
    range
});

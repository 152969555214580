"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatNumber = exports.formatNumberConsts = void 0;
const function_utils_1 = require("../utils/function-utils");
const MIN_PRECISION = 0;
const MAX_PRECISION = 20;
const DEFAULT_LOCAL = "en-US";
function validateLocalSupported(local) {
    let isLocalSupported;
    try {
        const options = { localeMatcher: "lookup" };
        isLocalSupported = !!Intl.NumberFormat.supportedLocalesOf((0, function_utils_1.convertToArray)(local), options).length;
    }
    catch (err) {
        isLocalSupported = false;
    }
    return isLocalSupported;
}
function validatePrecision(precision) {
    if (precision < MIN_PRECISION)
        return MIN_PRECISION;
    if (precision > MAX_PRECISION)
        return MAX_PRECISION;
    return precision;
}
exports.formatNumberConsts = Object.freeze({
    MIN_PRECISION,
    MAX_PRECISION,
    DEFAULT_LOCAL
});
function formatNumber(value, { local = DEFAULT_LOCAL, isCompact = true, precision = 2 } = {}) {
    if (value === undefined || value === null)
        return;
    const isLocalSupported = validateLocalSupported(local);
    const normalizedPrecision = validatePrecision(precision);
    const selectedLocal = isLocalSupported ? local : DEFAULT_LOCAL;
    return new Intl.NumberFormat(selectedLocal, Object.assign(Object.assign({}, (isCompact && { notation: "compact" })), { maximumFractionDigits: normalizedPrecision })).format(value);
}
exports.formatNumber = formatNumber;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getActualSize = exports.CounterSize = exports.CounterColor = exports.CounterType = void 0;
const sizes_1 = require("../../constants/sizes");
var CounterType;
(function (CounterType) {
    CounterType["FILL"] = "fill";
    CounterType["LINE"] = "line";
})(CounterType = exports.CounterType || (exports.CounterType = {}));
var CounterColor;
(function (CounterColor) {
    CounterColor["PRIMARY"] = "primary";
    CounterColor["DARK"] = "dark";
    CounterColor["NEGATIVE"] = "negative";
    CounterColor["LIGHT"] = "light";
})(CounterColor = exports.CounterColor || (exports.CounterColor = {}));
var CounterSize;
(function (CounterSize) {
    CounterSize["SMALL"] = "small";
    CounterSize["LARGE"] = "large";
})(CounterSize = exports.CounterSize || (exports.CounterSize = {}));
const OLD_COUNTER_SIZES = {
    sm: sizes_1.SIZES.SMALL,
    md: sizes_1.SIZES.MEDIUM,
    lg: sizes_1.SIZES.LARGE
};
const getActualSize = (size) => {
    if (size in OLD_COUNTER_SIZES) {
        // @ts-expect-error Element implicitly has an 'any' type because expression of type 'Size' can't be used to index typeof OLD_COUNTER_SIZES
        return OLD_COUNTER_SIZES[size];
    }
    return size;
};
exports.getActualSize = getActualSize;

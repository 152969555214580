"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MEDIA_QUERY_SIZES = exports.VIBE_MEDIA_QUERIES = exports.MEDIA_QUERY_QUERIES = exports.XLARGE = exports.LARGE = exports.MEDIUM2 = exports.MEDIUM1 = exports.SMALL2 = exports.SMALL1 = void 0;
exports.SMALL1 = 1;
exports.SMALL2 = 2;
exports.MEDIUM1 = 3;
exports.MEDIUM2 = 4;
exports.LARGE = 5;
exports.XLARGE = 6;
exports.MEDIA_QUERY_QUERIES = {
    [exports.SMALL1]: "screen and (max-width: 767px)",
    [exports.SMALL2]: "screen and (max-width: 1023px) and (min-width: 768px)",
    [exports.MEDIUM1]: "screen and (max-width: 1279px) and (min-width: 1024px)",
    [exports.MEDIUM2]: "screen and (max-width: 1439px) and (min-width: 1280px)",
    [exports.LARGE]: "screen and (max-width: 1919px) and (min-width: 1440px)",
    [exports.XLARGE]: "screen and (min-width: 1920px)"
};
exports.VIBE_MEDIA_QUERIES = [
    exports.MEDIA_QUERY_QUERIES[exports.SMALL1],
    exports.MEDIA_QUERY_QUERIES[exports.SMALL2],
    exports.MEDIA_QUERY_QUERIES[exports.MEDIUM1],
    exports.MEDIA_QUERY_QUERIES[exports.MEDIUM2],
    exports.MEDIA_QUERY_QUERIES[exports.LARGE],
    exports.MEDIA_QUERY_QUERIES[exports.XLARGE]
];
exports.MEDIA_QUERY_SIZES = {
    SMALL1: exports.SMALL1,
    SMALL2: exports.SMALL2,
    MEDIUM1: exports.MEDIUM1,
    MEDIUM2: exports.MEDIUM2,
    LARGE: exports.LARGE,
    XLARGE: exports.XLARGE
};

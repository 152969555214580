"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function usePrevious(value) {
    const ref = (0, react_1.useRef)(undefined);
    (0, react_1.useLayoutEffect)(() => {
        ref.current = value;
    });
    return ref.current;
}
exports.default = usePrevious;

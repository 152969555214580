"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogPosition = exports.SIZES = exports.BASE_SIZES_WITH_NONE = exports.BASE_SIZES = void 0;
exports.BASE_SIZES = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large"
};
exports.BASE_SIZES_WITH_NONE = Object.assign({ NONE: "none" }, exports.BASE_SIZES);
exports.SIZES = Object.assign({ XXS: "xxs", XS: "xs" }, exports.BASE_SIZES);
exports.DialogPosition = {
    LEFT: "left",
    LEFT_START: "left-start",
    LEFT_END: "left-end",
    RIGHT: "right",
    RIGHT_START: "right-start",
    RIGHT_END: "right-end",
    TOP: "top",
    TOP_START: "top-start",
    TOP_END: "top-end",
    BOTTOM: "bottom",
    BOTTOM_START: "bottom-start",
    BOTTOM_END: "bottom-end"
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const SliderConstants_1 = require("./SliderConstants");
const SliderHelpers_1 = require("./SliderHelpers");
const SliderInfixHooks_1 = require("./SliderInfixHooks");
const SliderInfix = ({ kind = SliderInfix.kinds.PREFIX }) => {
    const [isShow, modificators, InfixComponent, inlineStyle] = (0, SliderInfixHooks_1.useSliderInfixComponent)(kind);
    return (isShow && ((0, jsx_runtime_1.jsx)("div", Object.assign({ className: (0, SliderHelpers_1.bem)("infix", [...modificators, kind]), style: inlineStyle }, { children: InfixComponent }))));
};
SliderInfix.kinds = SliderConstants_1.InfixKind;
Object.assign(SliderInfix, {
    kinds: SliderConstants_1.InfixKind
});
exports.default = SliderInfix;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardButtonPressedFunc = void 0;
const react_1 = require("react");
const KEYDOWN_SPACE_EVENT = 32;
const KEYDOWN_ENTER_EVENT = 13;
function useKeyboardButtonPressedFunc(onClick) {
    return (0, react_1.useCallback)((e) => {
        if (e.keyCode === KEYDOWN_SPACE_EVENT || e.keyCode === KEYDOWN_ENTER_EVENT) {
            onClick(e);
        }
    }, [onClick]);
}
exports.useKeyboardButtonPressedFunc = useKeyboardButtonPressedFunc;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchDefaultIconNames = exports.SearchTypeClass = exports.SearchType = void 0;
var SearchType;
(function (SearchType) {
    SearchType["SQUARE"] = "square";
    SearchType["ROUND"] = "round";
    SearchType["UNDERLINE"] = "underline";
})(SearchType = exports.SearchType || (exports.SearchType = {}));
var SearchTypeClass;
(function (SearchTypeClass) {
    SearchTypeClass["round"] = "search_component--round";
    SearchTypeClass["underline"] = "input-component__input--only-underline";
    SearchTypeClass["square"] = "";
})(SearchTypeClass = exports.SearchTypeClass || (exports.SearchTypeClass = {}));
exports.SearchDefaultIconNames = {
    layout: "",
    primary: "Search",
    secondary: "Clear Search"
};

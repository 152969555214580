"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMAGE_POSITIONS = void 0;
var IMAGE_POSITIONS;
(function (IMAGE_POSITIONS) {
    IMAGE_POSITIONS["LEFT"] = "left";
    IMAGE_POSITIONS["RIGHT"] = "right";
    IMAGE_POSITIONS["TOP"] = "top";
    IMAGE_POSITIONS["BOTTOM"] = "bottom";
})(IMAGE_POSITIONS = exports.IMAGE_POSITIONS || (exports.IMAGE_POSITIONS = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors_vars_map_1 = require("./colors-vars-map");
const ColorUtils = {
    modes: colors_vars_map_1.COLOR_STYLES,
    contentColors: colors_vars_map_1.contentColors,
    getMondayColorAsStyle: (color, mode = colors_vars_map_1.COLOR_STYLES.REGULAR, withVar = true) => {
        return `${withVar ? "var(" : ""}--color-${color}${mode !== colors_vars_map_1.COLOR_STYLES.REGULAR ? `-${mode}` : ""}${withVar ? ")" : ""}`;
    }
};
exports.default = Object.freeze(ColorUtils);

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SliderColor = exports.InfixKind = exports.TOOLTIP_SHOW_DELAY = exports.UPDATE_SLIDER_SIZE_DEBOUNCE = exports.COMPONENT_ID = exports.BEM_PREFIX = void 0;
exports.BEM_PREFIX = "monday";
exports.COMPONENT_ID = "slider";
exports.UPDATE_SLIDER_SIZE_DEBOUNCE = 200;
exports.TOOLTIP_SHOW_DELAY = 300;
var InfixKind;
(function (InfixKind) {
    InfixKind["PREFIX"] = "prefix";
    InfixKind["POSTFIX"] = "postfix";
})(InfixKind = exports.InfixKind || (exports.InfixKind = {}));
var SliderColor;
(function (SliderColor) {
    SliderColor["PRIMARY"] = "primary";
    SliderColor["NEGATIVE"] = "negative";
    SliderColor["POSITIVE"] = "positive";
})(SliderColor = exports.SliderColor || (exports.SliderColor = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcActiveIndexAfterArrowNavigation = exports.getActiveIndexFromInboundNavigation = void 0;
const useFullKeyboardListeners_1 = require("../useFullKeyboardListeners");
function getActiveIndexFromInboundNavigation({ direction, numberOfItemsInLine, itemsCount }) {
    const getRawIndex = () => {
        const firstLineMiddleIndex = Math.floor(numberOfItemsInLine / 2);
        if (direction === useFullKeyboardListeners_1.NavDirections.UP) {
            // last line, middle
            const rowCount = Math.ceil(itemsCount / numberOfItemsInLine);
            return (rowCount - 1) * numberOfItemsInLine + firstLineMiddleIndex;
        }
        if (direction === useFullKeyboardListeners_1.NavDirections.DOWN) {
            // first line, middle
            return firstLineMiddleIndex;
        }
        if (direction === useFullKeyboardListeners_1.NavDirections.LEFT) {
            // middle line, last item
            let result = numberOfItemsInLine - 1;
            const midIndex = Math.floor((itemsCount - 1) / 2);
            while (result < midIndex) {
                result += numberOfItemsInLine;
            }
            return result;
        }
        if (direction === useFullKeyboardListeners_1.NavDirections.RIGHT) {
            // middle line, first item
            let result = 0;
            const midIndex = Math.floor((itemsCount - 1) / 2);
            while (result + numberOfItemsInLine < midIndex) {
                result += numberOfItemsInLine;
            }
            return result;
        }
    };
    const rawIndex = getRawIndex();
    return Math.max(0, Math.min(rawIndex, itemsCount - 1));
}
exports.getActiveIndexFromInboundNavigation = getActiveIndexFromInboundNavigation;
function calcRawNewIndexAfterArrowNavigation({ activeIndex, itemsCount, numberOfItemsInLine, direction }) {
    const getIndexLine = (index) => Math.ceil((index + 1) / numberOfItemsInLine);
    const horizontalChange = (isIndexIncrease) => {
        const nextIndex = activeIndex + (isIndexIncrease ? 1 : -1);
        if (nextIndex < 0 || itemsCount <= nextIndex) {
            return { isOutbound: true };
        }
        const currentLine = getIndexLine(activeIndex);
        const nextIndexLine = getIndexLine(nextIndex);
        if (currentLine !== nextIndexLine) {
            return { isOutbound: true };
        }
        return { isOutbound: false, nextIndex };
    };
    const verticalChange = (isIndexIncrease) => {
        const nextIndex = activeIndex + numberOfItemsInLine * (isIndexIncrease ? 1 : -1);
        if (nextIndex < 0 || itemsCount <= nextIndex) {
            return { isOutbound: true };
        }
        return { isOutbound: false, nextIndex };
    };
    switch (direction) {
        case useFullKeyboardListeners_1.NavDirections.RIGHT:
            return horizontalChange(true);
        case useFullKeyboardListeners_1.NavDirections.LEFT:
            return horizontalChange(false);
        case useFullKeyboardListeners_1.NavDirections.DOWN:
            return verticalChange(true);
        case useFullKeyboardListeners_1.NavDirections.UP:
            return verticalChange(false);
    }
}
function calcActiveIndexAfterArrowNavigation({ activeIndex, itemsCount, numberOfItemsInLine, direction, disabledIndexes = [] }) {
    let result = calcRawNewIndexAfterArrowNavigation({ activeIndex, itemsCount, numberOfItemsInLine, direction });
    while (!result.isOutbound && disabledIndexes.includes(result.nextIndex)) {
        result = calcRawNewIndexAfterArrowNavigation({
            activeIndex: result.nextIndex,
            itemsCount,
            numberOfItemsInLine,
            direction
        });
    }
    return result;
}
exports.calcActiveIndexAfterArrowNavigation = calcActiveIndexAfterArrowNavigation;

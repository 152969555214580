"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconPosition = exports.LinkTarget = void 0;
var LinkTarget;
(function (LinkTarget) {
    LinkTarget["NEW_WINDOW"] = "_blank";
    LinkTarget["SELF"] = "_self";
    LinkTarget["PARENT"] = "_parent";
    LinkTarget["TOP"] = "_top";
})(LinkTarget = exports.LinkTarget || (exports.LinkTarget = {}));
var IconPosition;
(function (IconPosition) {
    IconPosition["START"] = "start";
    IconPosition["END"] = "end";
})(IconPosition = exports.IconPosition || (exports.IconPosition = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
// the second argument of useReducer is the dispatch function
// after calling dispatch we will set a new object in the state
// if the state will change by reference it will force rerender of the component
const reducerAlwaysReturningNewObj = () => ({});
function useForceUpdate() {
    return (0, react_1.useReducer)(reducerAlwaysReturningNewObj, {})[1];
}
exports.default = useForceUpdate;

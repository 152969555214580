"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSwitchChecked = void 0;
const react_1 = require("react");
function useSwitchChecked({ checked, defaultChecked, onChange }) {
    const overrideCheckedInitial = checked === undefined ? (defaultChecked ? defaultChecked : false) : checked;
    const [overrideChecked, setOverrideChecked] = (0, react_1.useState)(overrideCheckedInitial);
    const overrideOnChange = (0, react_1.useCallback)((e) => {
        if (checked === undefined) {
            setOverrideChecked(!overrideChecked);
        }
        onChange(e.currentTarget.checked);
    }, [checked, onChange, overrideChecked]);
    (0, react_1.useEffect)(() => {
        checked !== undefined && setOverrideChecked(checked);
    }, [checked]);
    return { checked: overrideChecked, onChange: overrideOnChange };
}
exports.useSwitchChecked = useSwitchChecked;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useActiveDescendantListFocusHooks_1 = require("./useActiveDescendantListFocusHooks");
var Role;
(function (Role) {
    Role["APPLICATION"] = "application";
    Role["COMBOBOX"] = "combobox";
    Role["COMPOSITE"] = "composite";
    Role["GROUP"] = "group";
    Role["TEXTBOX"] = "textbox";
    Role["MENU"] = "menu";
})(Role || (Role = {}));
function useActiveDescendantListFocus({ focusedElementRef, // the reference for the component that listens to keyboard
itemsIds, isItemSelectable, onItemClick, defaultVisualFocusFirstIndex = false, focusedElementRole = Role.GROUP, isHorizontalList = false, useDocumentEventListeners = false, isIgnoreSpaceAsItemSelection = false }) {
    const defaultVisualFocusItemIndex = defaultVisualFocusFirstIndex ? 0 : -1;
    const itemsCount = itemsIds.length;
    const [visualFocusItemIndex, setVisualFocusItemIndex] = (0, react_1.useState)(-1);
    const visualFocusItemId = itemsIds[visualFocusItemIndex];
    const listenerOptions = (0, react_1.useMemo)(() => {
        if (useDocumentEventListeners)
            return undefined;
        return {
            ref: focusedElementRef,
            preventDefault: true,
            stopPropagation: true
        };
    }, [useDocumentEventListeners, focusedElementRef]);
    const { triggeredByKeyboard } = (0, useActiveDescendantListFocusHooks_1.useSetDefaultItemOnFocusEvent)({
        focusedElementRef,
        isItemSelectable,
        visualFocusItemIndex,
        setVisualFocusItemIndex,
        itemsCount,
        defaultVisualFocusItemIndex
    });
    const setVisualFocusItemId = (0, react_1.useCallback)((visualFocusItemId, isTriggeredByKeyboard) => {
        triggeredByKeyboard.current = isTriggeredByKeyboard;
        const itemIndex = itemsIds.indexOf(visualFocusItemId);
        if (itemIndex > -1 && itemIndex !== visualFocusItemIndex) {
            setVisualFocusItemIndex(itemIndex);
        }
    }, [itemsIds, triggeredByKeyboard, visualFocusItemIndex]);
    (0, useActiveDescendantListFocusHooks_1.useSupportArrowsKeyboardNavigation)({
        itemsCount,
        focusedElementRef,
        visualFocusItemIndex,
        setVisualFocusItemIndex,
        triggeredByKeyboard,
        isHorizontalList,
        isItemSelectable,
        listenerOptions
    });
    (0, useActiveDescendantListFocusHooks_1.useSupportPressItemKeyboardNavigation)({
        visualFocusItemIndex,
        itemsCount,
        focusedElementRef,
        setVisualFocusItemIndex,
        onItemClick,
        isItemSelectable,
        listenerOptions,
        isIgnoreSpaceAsItemSelection
    });
    (0, useActiveDescendantListFocusHooks_1.useKeepFocusOnItemWhenListChanged)({
        visualFocusItemIndex,
        itemsIds,
        isItemSelectable,
        setVisualFocusItemIndex
    });
    (0, useActiveDescendantListFocusHooks_1.useCleanVisualFocusOnBlur)({ focusedElementRef, visualFocusItemIndex, setVisualFocusItemIndex });
    // this callback function is not needed anymore (the developer does not need to replace  the element's on click with this callback).
    // we keep it for backward compatibility
    const backwardCompatibilityCreateOnClickCallback = (0, react_1.useCallback)((itemIndex) => (event) => onItemClick(event, itemIndex), [onItemClick]);
    return {
        visualFocusItemIndex: triggeredByKeyboard.current ? visualFocusItemIndex : undefined,
        visualFocusItemId: triggeredByKeyboard.current ? visualFocusItemId : undefined,
        focusedElementProps: {
            "aria-activedescendant": triggeredByKeyboard.current ? visualFocusItemId : undefined,
            role: focusedElementRole
        },
        // this callback function is not needed anymore (the developer does not need to replace  the element's on click with this callback).
        // we keep it for backward compatibility
        onItemClickCallback: onItemClick,
        createOnItemClickCallback: backwardCompatibilityCreateOnClickCallback,
        setVisualFocusItemId
    };
}
useActiveDescendantListFocus.roles = Role;
exports.default = useActiveDescendantListFocus;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMediaQuery = void 0;
const react_1 = require("react");
function useMediaQuery(query) {
    const queries = (0, react_1.useMemo)(() => {
        return Array.isArray(query) ? query : [query];
    }, [query]);
    const [matches, setMatches] = (0, react_1.useState)(queries.map(query => !!window.matchMedia(query).matches));
    (0, react_1.useLayoutEffect)(() => {
        const mediaQueryList = queries.map(query => window.matchMedia(query));
        const updated = mediaQueryList.map((query, index) => {
            // we save the callback function so when we unmount we could remove the listener
            const callback = (event) => {
                setMatches(prevState => {
                    const newQueries = [...prevState];
                    newQueries[index] = event.matches;
                    return newQueries;
                });
            };
            query.addEventListener("change", callback);
            return [query, callback];
        });
        return () => {
            updated.forEach(([query, callback]) => {
                query.removeEventListener("change", callback);
            });
        };
    }, [queries, setMatches]);
    return matches;
}
exports.useMediaQuery = useMediaQuery;
exports.default = useMediaQuery;

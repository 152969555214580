"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SIZE_MAPPER = exports.FEEDBACK_CLASSES = exports.TextFieldFeedbackState = exports.TextFieldTextType = exports.getActualSize = exports.TextFieldAriaLabel = void 0;
const sizes_1 = require("../../constants/sizes");
var TextFieldAriaLabel;
(function (TextFieldAriaLabel) {
    TextFieldAriaLabel["CHAR"] = "Input char count";
    TextFieldAriaLabel["VALIDATION_TEXT"] = "Additional helper text";
})(TextFieldAriaLabel = exports.TextFieldAriaLabel || (exports.TextFieldAriaLabel = {}));
const OLD_TEXT_FIELD_SIZES = {
    s: sizes_1.BASE_SIZES.SMALL,
    md: sizes_1.BASE_SIZES.MEDIUM,
    l: sizes_1.BASE_SIZES.LARGE
};
// Support old sizes (backward compatible)
const getActualSize = (size) => {
    if (size in OLD_TEXT_FIELD_SIZES) {
        return OLD_TEXT_FIELD_SIZES[size];
    }
    return size;
};
exports.getActualSize = getActualSize;
var TextFieldTextType;
(function (TextFieldTextType) {
    TextFieldTextType["TEXT"] = "text";
    TextFieldTextType["PASSWORD"] = "password";
    TextFieldTextType["SEARCH"] = "search";
    TextFieldTextType["DATE"] = "date";
    TextFieldTextType["DATE_TIME"] = "datetime-local";
})(TextFieldTextType = exports.TextFieldTextType || (exports.TextFieldTextType = {}));
var TextFieldFeedbackState;
(function (TextFieldFeedbackState) {
    TextFieldFeedbackState["ERROR"] = "error";
    TextFieldFeedbackState["SUCCESS"] = "success";
})(TextFieldFeedbackState = exports.TextFieldFeedbackState || (exports.TextFieldFeedbackState = {}));
exports.FEEDBACK_CLASSES = {
    [TextFieldFeedbackState.ERROR]: "input-component__input--error-validation",
    [TextFieldFeedbackState.SUCCESS]: "input-component__input--success-validation"
};
exports.SIZE_MAPPER = {
    [sizes_1.BASE_SIZES.SMALL]: "input-component__input-wrapper--size-small",
    [sizes_1.BASE_SIZES.MEDIUM]: "input-component__input-wrapper--size-medium",
    [sizes_1.BASE_SIZES.LARGE]: "input-component__input-wrapper--size-large"
};

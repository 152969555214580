"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BreadcrumbContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
const react_1 = require("react");
const constants_1 = require("../../../../constants");
require("./BreadcrumbContent.scss");
const iconProps = { className: "breadcrumb-icon", size: 14, clickable: false };
exports.BreadcrumbContent = (0, react_1.forwardRef)(({ className, isClickable, link, onClick, text, icon, isCurrent, disabled = false }, ref) => {
    const Icon = icon;
    const onKeyDown = (0, react_1.useCallback)((event) => {
        if (event.key === constants_1.keyCodes.ENTER || event.key === constants_1.keyCodes.SPACE) {
            link ? (window.parent.location.href = link) : onClick();
        }
    }, [onClick, link]);
    const tabIndex = (0, react_1.useMemo)(() => (disabled ? -1 : 0), [disabled]);
    if (isClickable && (link || onClick)) {
        if (link) {
            return ((0, jsx_runtime_1.jsxs)("a", Object.assign({ className: className, href: link, onKeyDown: onKeyDown, "aria-current": isCurrent ? "page" : undefined }, { children: [Icon && (0, jsx_runtime_1.jsx)(Icon, Object.assign({}, iconProps)), (0, jsx_runtime_1.jsx)("span", Object.assign({ ref: ref, className: "breadcrumb-text" }, { children: text }))] })));
        }
        return ((0, jsx_runtime_1.jsxs)("span", Object.assign({ className: className, onClick: onClick, onKeyDown: onKeyDown, tabIndex: tabIndex, "aria-current": isCurrent ? "page" : undefined, role: "button" }, { children: [Icon && (0, jsx_runtime_1.jsx)(Icon, Object.assign({}, iconProps)), (0, jsx_runtime_1.jsx)("span", Object.assign({ ref: ref, className: "breadcrumb-text" }, { children: text }))] })));
    }
    return ((0, jsx_runtime_1.jsxs)("span", Object.assign({ className: className, "aria-disabled": "true", tabIndex: tabIndex, "aria-current": isCurrent ? "page" : undefined }, { children: [Icon && (0, jsx_runtime_1.jsx)(Icon, Object.assign({}, iconProps)), (0, jsx_runtime_1.jsx)("span", Object.assign({ ref: ref, className: "breadcrumb-text" }, { children: text }))] })));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttentionBoxType = void 0;
var AttentionBoxType;
(function (AttentionBoxType) {
    AttentionBoxType["PRIMARY"] = "primary";
    AttentionBoxType["SUCCESS"] = "success";
    AttentionBoxType["DANGER"] = "danger";
    AttentionBoxType["DARK"] = "dark";
})(AttentionBoxType = exports.AttentionBoxType || (exports.AttentionBoxType = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShiftTabEvent = exports.isHomeEvent = exports.isEndEvent = exports.isPageDownEvent = exports.isPageUpEvent = exports.isTabEvent = exports.isSpaceEvent = exports.isBackspaceEvent = exports.isArrowLeftEvent = exports.isArrowRightEvent = exports.isArrowDownEvent = exports.isArrowUpEvent = exports.isEscapeEvent = exports.isShiftEnterEvent = exports.isEnterEvent = void 0;
const ENTER_KEY_CODE = 13;
const ESCAPE_KEY_CODE = 27;
const BACKSPACE = 8;
const TAB = 9;
const SPACE = 32;
const PAGE_UP = 33;
const PAGE_DOWN = 34;
const END = 35;
const HOME = 36;
const ARROW_LEFT_KEY_CODE = 37;
const ARROW_UP_KEY_CODE = 38;
const ARROW_RIGHT_KEY_CODE = 39;
const ARROW_DOWN_KEY_CODE = 40;
function validateEvent(event, keyCode, key) {
    if (!event)
        return false;
    return event.keyCode === keyCode || event.which === keyCode || (key && event.key === key);
}
function isEnterEvent(event) {
    return validateEvent(event, ENTER_KEY_CODE);
}
exports.isEnterEvent = isEnterEvent;
function isShiftEnterEvent(event) {
    return event.shiftKey && isEnterEvent(event);
}
exports.isShiftEnterEvent = isShiftEnterEvent;
function isEscapeEvent(event) {
    return validateEvent(event, ESCAPE_KEY_CODE);
}
exports.isEscapeEvent = isEscapeEvent;
function isArrowUpEvent(event) {
    return validateEvent(event, ARROW_UP_KEY_CODE);
}
exports.isArrowUpEvent = isArrowUpEvent;
function isArrowDownEvent(event) {
    return validateEvent(event, ARROW_DOWN_KEY_CODE);
}
exports.isArrowDownEvent = isArrowDownEvent;
function isArrowRightEvent(event) {
    return validateEvent(event, ARROW_RIGHT_KEY_CODE);
}
exports.isArrowRightEvent = isArrowRightEvent;
function isArrowLeftEvent(event) {
    return validateEvent(event, ARROW_LEFT_KEY_CODE);
}
exports.isArrowLeftEvent = isArrowLeftEvent;
function isBackspaceEvent(event) {
    return validateEvent(event, BACKSPACE);
}
exports.isBackspaceEvent = isBackspaceEvent;
function isSpaceEvent(event) {
    return validateEvent(event, SPACE);
}
exports.isSpaceEvent = isSpaceEvent;
function isTabEvent(event) {
    return validateEvent(event, TAB);
}
exports.isTabEvent = isTabEvent;
function isPageUpEvent(event) {
    return validateEvent(event, PAGE_UP);
}
exports.isPageUpEvent = isPageUpEvent;
function isPageDownEvent(event) {
    return validateEvent(event, PAGE_DOWN);
}
exports.isPageDownEvent = isPageDownEvent;
function isEndEvent(event) {
    return validateEvent(event, END);
}
exports.isEndEvent = isEndEvent;
function isHomeEvent(event) {
    return validateEvent(event, HOME);
}
exports.isHomeEvent = isHomeEvent;
function isShiftTabEvent(event) {
    return event.shiftKey && isTabEvent(event);
}
exports.isShiftTabEvent = isShiftTabEvent;

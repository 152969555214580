export const HIDE_SHOW_EVENTS = {
  CLICK: "click",
  CLICK_OUTSIDE: "clickoutside",
  ESCAPE_KEY: "esckey",
  TAB_KEY: "tab",
  MOUSE_ENTER: "mouseenter",
  MOUSE_LEAVE: "mouseleave",
  ENTER: "enter",
  MOUSE_DOWN: "mousedown",
  FOCUS: "focus",
  BLUR: "blur",
  CONTENT_CLICK: "onContentClick"
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFirefox = exports.getBrowserName = void 0;
const detect_browser_1 = require("detect-browser");
const browser = (0, detect_browser_1.detect)();
function getBrowserName() {
    if (browser && browser.name) {
        return browser.name;
    }
}
exports.getBrowserName = getBrowserName;
function isFirefox() {
    return getBrowserName() === "firefox";
}
exports.isFirefox = isFirefox;

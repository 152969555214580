"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFocusWithin = void 0;
const react_1 = require("react");
function useFocusWithin({ onFocusWithin, onFocusWithinChange, isDisabled, onBlurWithin }) {
    const state = (0, react_1.useRef)({
        isFocusWithin: false
    }).current;
    const onFocus = (0, react_1.useCallback)((e) => {
        if (!state.isFocusWithin) {
            if (onFocusWithin) {
                onFocusWithin(e);
            }
            if (onFocusWithinChange) {
                onFocusWithinChange(true);
            }
            state.isFocusWithin = true;
        }
    }, [onFocusWithin, onFocusWithinChange, state]);
    const onBlur = (0, react_1.useCallback)((e) => {
        // We don't want to trigger onBlurWithin and then immediately onFocusWithin again
        // when moving focus inside the element. Only trigger if the currentTarget doesn't
        // include the relatedTarget (where focus is moving).
        const currentTarget = e.currentTarget;
        if (state.isFocusWithin && !currentTarget.contains(e.relatedTarget)) {
            if (onBlurWithin) {
                onBlurWithin(e);
            }
            if (onFocusWithinChange) {
                onFocusWithinChange(false);
            }
            state.isFocusWithin = false;
        }
    }, [onBlurWithin, onFocusWithinChange, state]);
    const isDisabledReturnValue = (0, react_1.useMemo)(() => {
        if (!isDisabled)
            return {};
        return { focusWithinProps: {} };
    }, [isDisabled]);
    if (isDisabled) {
        return isDisabledReturnValue;
    }
    return {
        focusWithinProps: {
            onFocus: onFocus,
            onBlur: onBlur
        }
    };
}
exports.useFocusWithin = useFocusWithin;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const function_utils_1 = require("../../../utils/function-utils");
const SliderContext_1 = require("../SliderContext");
const SliderHelpers_1 = require("../SliderHelpers");
const SliderRail = (0, react_1.forwardRef)(({ className, children, onClick = function_utils_1.NOOP }, ref) => {
    const { shapeTestId } = (0, SliderContext_1.useSliderUi)();
    function handleClick(e) {
        onClick(e);
    }
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    (0, jsx_runtime_1.jsx)("div", Object.assign({ "data-testid": shapeTestId("rail"), className: (0, SliderHelpers_1.bem)("rail", "", className), onClick: handleClick, ref: ref }, { children: children })));
});
exports.default = SliderRail;

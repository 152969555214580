"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertBannerBackgroundColor = void 0;
var AlertBannerBackgroundColor;
(function (AlertBannerBackgroundColor) {
    AlertBannerBackgroundColor["PRIMARY"] = "primary";
    AlertBannerBackgroundColor["POSITIVE"] = "positive";
    AlertBannerBackgroundColor["NEGATIVE"] = "negative";
    AlertBannerBackgroundColor["DARK"] = "dark";
})(AlertBannerBackgroundColor = exports.AlertBannerBackgroundColor || (exports.AlertBannerBackgroundColor = {}));
